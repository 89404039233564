import { template as template_7969c365d4994e0b9698746b50454ec2 } from "@ember/template-compiler";
const FKLabel = template_7969c365d4994e0b9698746b50454ec2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
